<div class="select-date-wrapper">

  <h1 class="text-center text-xl md:text-2xl" translate>contact_info.contact_info</h1>
  
  <div class="flex flex-row justify-center">
    <div class=" font-thin text-sm" [translateParams]="{ guests: numberOfGuests }" translate>stepper.date_summary</div>
    <div style="width: 40px;"></div>
    <div class="font-thin text-sm">{{ selectedDate | localizedDateString }} </div>
    <div style="width: 40px;"></div>
    <div class="font-thin text-sm">{{ selectedTimeHumanReadable }}</div>
  </div>

  <form (ngSubmit)="submit()" [formGroup]="contactInfoForm" class="contact-form mt-12">
    <div class="mt-7">
      <label translate>contact_info.firstname</label>
      <input formControlName="firstname" type="text" [placeholder]="'contact_info.firstname' | translate" class="input input-bordered w-full"/>
      <p *ngIf="contactInfoForm.controls['firstname'].invalid && contactInfoForm.controls['firstname'].touched" translate class="required">
        contact_info.required_field
      </p>
    </div>

    <div class="mt-7">
      <label translate>contact_info.lastname</label>
      <input formControlName="lastname" type="text" [placeholder]="'contact_info.lastname' | translate" class="input input-bordered w-full"/>
      <p *ngIf="contactInfoForm.controls['lastname'].invalid && contactInfoForm.controls['lastname'].touched" translate class="required">
        contact_info.required_field
      </p>
    </div>

    <div class="mt-7">
      <label translate>contact_info.email</label>
      <input formControlName="email" type="email" placeholder="example@gmail.com" class="input input-bordered w-full"/>
      <p *ngIf="contactInfoForm.controls['email'].invalid && contactInfoForm.controls['email'].touched" translate class="required">
        contact_info.required_field_email
      </p>
    </div>
    <div class="mt-7">

      <ngx-material-intl-tel-input
        [emojiFlags]="true"
        [textLabels]="textLabelsPhoneInput"
        [fieldControl]="contactInfoForm.controls.phone"
        fieldControlName="phone"
        [required]="true"
        [hidePhoneIcon]="true"
        [autoIpLookup]="true"
        [autoSelectCountry]="true"
        [autoSelectedCountry]="countryISO"
      /> 

      <p *ngIf="contactInfoForm.controls['phone'].invalid && contactInfoForm.controls['phone'].touched" translate class="required">
        contact_info.required_field_phone
      </p>
    </div>
    <div class="mt-7 flex flex-col">
      <label translate>contact_info.comment</label>
      <textarea formControlName="comment" type="text" [placeholder]="'contact_info.comment_placeholder' | translate" class="textarea textarea-bordered"></textarea>
      <p *ngIf="contactInfoForm.controls['comment'].invalid && contactInfoForm.controls['comment'].touched" translate class="required">
        contact_info.required_field
      </p>
    </div>

    <div class="mt-7 flex items-center">
      <label for="termsAndConditionsLinkInput" class="flex content-center  gap-2">
        <input formControlName="gdprConsent" type="checkbox" class="checkbox checkbox-primary" id="termsAndConditionsLinkInput"/>
        <div>
          <span class="" translate>contact_info.consent</span>
          <span class="" *ngIf="termsAndConditionsLink">(<a [href]="termsAndConditionsLink" target="_blank" class="underline" translate>common.readmore</a>)</span>
        </div>
      </label>
    </div>
    <p *ngIf="contactInfoForm.controls['gdprConsent'].invalid && contactInfoForm.controls['gdprConsent'].touched" translate class="required">
      contact_info.required_field
    </p>

    <div class="mt-7 flex items-center">
      <label for="marketingLinkInput" class="flex content-center gap-2">
        <input formControlName="marketingConsent" type="checkbox" class="checkbox checkbox-primary" id="marketingLinkInput" />
        <div>
          <span class="" translate>contact_info.marketingConsent</span>
          <span class="" *ngIf="marketingLink">(<a [href]="marketingLink" target="_blank" class="underline" translate>common.readmore</a>)</span>
        </div>
      </label>
    </div>

    <div class="flex justify-between mt-12">
      <button (click)="back()" type="button" class="btn btn-outline" [disabled]="isLoading">
        <span translate>common.back</span>
      </button>
      <button type="submit" class="btn btn-primary" [disabled]="isLoading">
        <app-loader *ngIf="isLoading"></app-loader>
        <span *ngIf="!isLoading" translate>common.reserve</span>
      </button>
    </div>
  </form>
</div>
