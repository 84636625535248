import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { I18nService } from '@app/i18n';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, merge, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-missing-attributes-error',
  templateUrl: './missing-attributes-error.component.html',
  styleUrls: ['./missing-attributes-error.component.css']
})
export class MissingAttributesErrorComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private titleService: Title,
    private i18nService: I18nService,
  ) { }

  async ngOnInit() {

    // Emit event to display loader.
    const displayLoaderEvent = new CustomEvent('displayFrameworkLoader');
    window.dispatchEvent(displayLoaderEvent);

    this.i18nSetup()
      .finally(() => {
        const event = new CustomEvent('hideFrameworkLoader');
        window.dispatchEvent(event);
      })

  }

  private async i18nSetup() {
    // Setup translations
    await this.i18nService.init(environment.defaultLanguage);
    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd).pipe(
      map(() => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      switchMap(route => route.data),
      untilDestroyed(this),
    );

    this.titleService.setTitle(this.translateService.instant('route.title.attribute_error'));
  };

  ngOnDestroy() {
    this.i18nService.destroy();
  };
}
