<div class="container-background-color mt-12 flex flex-col items-center gap-4 pt-20 pb-10 {{classes}}">

  <div class="absolute top-[10px] right-[10px]" *ngIf="!initialLoaderToFixBuxInLanguageSelector">
    <app-language-selector />
  </div>

  <div class="flex flex-col items-center text-center">
    <h1 class="text-3xl md:text-3xl">{{restaurantName}}</h1>
    <p class="max-[320px]:text-xs" *ngIf="address && city && country">
      <a [href]="'https://www.google.com/maps/search/?api=1&query=' + restaurantName + ',' + city + ',' + country" target="_blank">
        {{address}}, {{city}}
      </a>
    </p>
  </div>

  <div class="w-full sm:w-[500px] flex flex-col gap-5 p-4 text-center" style="{{isPreviewMode ? 'margin-top: 20px!important' : ''}}">
    <h1 class="text-center text-2xl mt-12" translate>waitinglist.join</h1>
    <p translate>waitinglist.desc</p>

    <form (ngSubmit)="submit()" [formGroup]="form" class="flex flex-col gap-16 mt-12">
      <div>
        <h2 class="text-2xl">{{selectedDate | localizedDateString: dateFormatOptions }}</h2>
      </div>
      <div>
        <h2 class="text-2xl" translate>select_time.title</h2>
        <select class="select select-bordered w-full max-w-xs mt-5" formControlName="desiredReservationTime" style="font-size: 24px; color: black;">
          <option *ngFor="let time of availableTimes" [value]="time.minutesFromMidnight">{{time.timeString}}</option>
        </select>
      </div>

      <div>
        <h2 class="text-2xl" translate>waitinglist.flexible</h2>
        <div class="guest-buttons radioButtons">
          <div class="flex items-center radioButton">
            <input type="radio" formControlName="flexibility" [value]="0" class="roundedPrimaryButton" id="flex-zero" />
            <label for="flex-zero" class="ml-2" style="width: auto; padding: 15px;" translate>common.exact</label>
          </div>
          <div class="flex items-center radioButton">
            <input type="radio" formControlName="flexibility" [value]="30" class="roundedPrimaryButton" id="flex-thirty"/>
            <label for="flex-thirty" class="ml-2" style="width: auto; padding: 15px;">± 30 {{'common.minutes' | translate}}</label>
          </div>
          <div class="flex items-center radioButton">
            <input type="radio" formControlName="flexibility" [value]="60" class="roundedPrimaryButton" id="flex-sixty"/>
            <label for="flex-sixty" class="ml-2" style="width: auto; padding: 15px;">± 1 {{'common.hour' | translate}}</label>
          </div>
          <div class="flex items-center radioButton">
            <input type="radio" formControlName="flexibility" [value]="120" class="roundedPrimaryButton" id="flex-onetwenty"/>
            <label for="flex-onetwenty" class="ml-2" style="width: auto; padding: 15px;">± 2 {{'common.hours' | translate}}</label>
          </div>
        </div>
      </div>

      <div>
        <h2 class="text-2xl" translate>select_guests.title</h2>
        <div class="guest-buttons radioButtons">
          <div class="radioButton" *ngFor="let item of [].constructor(maxGuests); let i = index">
            <input
              [id]="'number-of-guests-option' + (i + 1)"
              formControlName="numberOfGuests"
              [value]="i + 1"
              type="radio"
              class="roundedPrimaryButton"
            />
            <label [for]="'number-of-guests-option' + (i + 1)">{{ i + 1 }}</label>
          </div>
        </div>
      </div>

      <div>
        <h2 class="text-2xl" translate>common.contact_info</h2>


        <div class="text-left">
          <div class="mt-7">
            <label translate>contact_info.firstname</label>
            <input formControlName="firstname" type="text" [placeholder]="'contact_info.firstname' | translate" class="input input-bordered w-full"/>
            <p *ngIf="form.controls['firstname'].invalid && form.controls['firstname'].touched" translate class="required">
              contact_info.required_field
            </p>
          </div>

          <div class="mt-7">
            <label translate>contact_info.lastname</label>
            <input formControlName="lastname" type="text" [placeholder]="'contact_info.lastname' | translate" class="input input-bordered w-full"/>
            <p *ngIf="form.controls['lastname'].invalid && form.controls['lastname'].touched" translate class="required">
              contact_info.required_field
            </p>
          </div>

          <div class="mt-7">
            <label translate>contact_info.email</label>
            <input formControlName="email" type="email" placeholder="example@gmail.com" class="input input-bordered w-full"/>
            <p *ngIf="form.controls['email'].invalid && form.controls['email'].touched" translate class="required">
              contact_info.required_field_email
            </p>
          </div>
          <div class="mt-7">
            <app-phone-input
              [restaurantCountry]="country"
              (phoneNumberChange)="addPhoneNumberToForm($event)"
            >
            </app-phone-input>
            <p *ngIf="form.controls['phone'].invalid && form.controls['phone'].touched" translate class="required">
              contact_info.required_field_phone
            </p>
          </div>
          <div class="mt-7 flex flex-col">
            <label translate>contact_info.comment</label>
            <textarea formControlName="comment" type="text" [placeholder]="'contact_info.comment_placeholder' | translate" class="textarea textarea-bordered"></textarea>
            <p *ngIf="form.controls['comment'].invalid && form.controls['comment'].touched" translate class="required">
              contact_info.required_field
            </p>
          </div>

          <div class="mt-7 flex items-center">
            <label for="termsAndConditionsLinkInput" class="flex gap-2 items-center">
              <input formControlName="gdprConsent" type="checkbox" class="checkbox checkbox-primary" id="termsAndConditionsLinkInput" />

              <div>
                <span class="" translate>contact_info.consent</span>
                <span class="" *ngIf="termsAndConditionsLink">(<a [href]="termsAndConditionsLink" target="_blank" class="underline" translate>common.readmore</a>)</span>
              </div>
            </label>
          </div>
          <p *ngIf="form.controls['gdprConsent'].invalid && form.controls['gdprConsent'].touched" translate class="required">
            contact_info.required_field
          </p>

          <div class="mt-7 flex items-center">
            <label for="marketingLinkInput" class="flex gap-2 items-center" translate>
              <input formControlName="marketingConsent" type="checkbox" class="checkbox checkbox-primary" id="marketingLinkInput"/>
              <div>
                <span class="" translate>contact_info.marketingConsent</span>
                <span class="" *ngIf="marketingLink">(<a [href]="marketingLink" target="_blank" class="underline" translate>common.readmore</a>)</span>
              </div>
            </label>
          </div>

          <div *ngIf="showFormNotValidMessage" class="mt-12 text-center" translate>waitinglist.showFormNotValidMessage</div>

          <div class="flex justify-between mt-12">
            <button (click)="back()" type="button" class="btn btn-outline">
              <span translate>common.back</span>
            </button>
            <!-- ngClass blir brukt istedenfor TailwindCSS 'disabled: selector' da denne ikke fungerer som forventet. -->
            <button type="submit" class="btn btn-primary" [disabled]="isLoading">
              <app-loader *ngIf="isLoading"></app-loader>
              <span *ngIf="!isLoading" translate>common.joinwaitlist</span>
            </button>

          </div>
        </div>
      </div>

    </form>

  </div>

  <div class="text-center text-[#adadad]">
    <p>{{'common.deliveredByResmium' | translate }} <a href="https://resmium.com">Resmium</a></p>
  </div>



</div>
