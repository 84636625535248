import { Component, OnInit, OnDestroy, ViewEncapsulation, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { I18nService } from '@app/i18n';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SupabaseService } from '@app/services/supabase.service';
import { hexToRgb } from '@app/utils/hex-to-rgb';
import { WidgetConfigurations } from '@app/models/widget-configurations.model';
import * as Sentry from "@sentry/angular";
import { Title } from '@angular/platform-browser';
import { filter, map, merge, switchMap } from 'rxjs';


@UntilDestroy()
@Component({
  selector: 'booking-review-page',
  templateUrl: './cancellation-confirmed.component.html',
  styleUrls: ['./cancellation-confirmed.component.css'],

})
export class CancellationConfirmedComponent implements OnInit, OnDestroy {

  @Input() bookingUuid?: string;

  tenantId?: string;
  restaurantId?: string;

  isLoading = true;

  // Widget Configuration
  isLoadingWidgetConfigurations: boolean = true;
  classes: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private titleService: Title,
    private i18nService: I18nService,
    private supabaseService: SupabaseService,
  ) {};

  async ngOnInit() {

    // Emit event to display loader.
    const displayLoaderEvent = new CustomEvent('displayFrameworkLoader');
    window.dispatchEvent(displayLoaderEvent);

    const params = this.activatedRoute.snapshot.queryParams;
    this.tenantId = params.tenantId;
    this.restaurantId = params.restaurantId;

    this.i18nSetup()
    .then(() => {
      this.getWidgetConfigurations();
    
      Sentry.setUser({
        'Tenant Id': this.tenantId,
        'Restaurant Id': this.restaurantId,
      })
    })
    .finally(() => {
      this.isLoading = false;
      const event = new CustomEvent('hideFrameworkLoader');
      window.dispatchEvent(event);
    })

  }
 
    private async i18nSetup() {
      // Setup translations
      await this.i18nService.init(environment.defaultLanguage);
      const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));
  
      // Change page title on navigation or language change, based on route data
      merge(this.translateService.onLangChange, onNavigationEnd).pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        switchMap(route => route.data),
        untilDestroyed(this),
      );
  
      this.titleService.setTitle(this.translateService.instant('route.title.cancellation'));
    };

  ngOnDestroy() {
    this.i18nService.destroy();
  };


  private getWidgetConfigurations() {
    if (this.tenantId) {
      this.supabaseService.getWidgetConfigurations(this.tenantId!, this.restaurantId!).subscribe({
        next: response => {
          if(response.error) throw response.error;
          const data = response.data;
          if (!data) {
            return;
          }
          this.setStyle(data);
        },
        error: error => {
          console.error(error);
        },
        complete: () => {
          this.isLoadingWidgetConfigurations = false;
        }
      });
    }
  };

  private setStyle(widgetConfigurations: WidgetConfigurations){
    if(widgetConfigurations.primaryColor){
      document.documentElement.style.setProperty('--primary-color', widgetConfigurations.primaryColor);
      // document.documentElement.style.setProperty('--p', widgetConfigurations.primaryColor);
      const primaryColorLight = hexToRgb(widgetConfigurations.primaryColor);
      // create a lighter color if primar color is set with opacity
      if(primaryColorLight){
        document.documentElement.style.setProperty('--primary-color-lighter', `rgba(${primaryColorLight.r}, ${primaryColorLight.g}, ${primaryColorLight.b}, 0.5)`);
      }
    }
    if(widgetConfigurations.primaryColorContrast){
      document.documentElement.style.setProperty('--primary-color-contrast', widgetConfigurations.primaryColorContrast);
    }
    if(widgetConfigurations.hasFrame){
      this.classes += ' shadow-xl';
    }

    if(widgetConfigurations.font){
      document.documentElement.style.fontFamily = widgetConfigurations.font;
      document.body.style.fontFamily = widgetConfigurations.font;
    }

    if(widgetConfigurations.classes){
      this.classes += ' ' + widgetConfigurations.classes;
    }

    if(widgetConfigurations.fontColor){
      document.body.style.color = widgetConfigurations.fontColor;
    }

    if(widgetConfigurations.borderRadius){
      document.documentElement.style.setProperty('--border-radius', widgetConfigurations.borderRadius + 'px');
    }

    if(widgetConfigurations.containerColor){
      document.documentElement.style.setProperty('--container-color', widgetConfigurations.containerColor);
    }

    if(widgetConfigurations.backgroundColor){
      document.body.style.backgroundColor = widgetConfigurations.backgroundColor;
    }

  };


};
