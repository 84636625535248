import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { convertMinutesSinceMidnightToHumanReadableTime } from '@app/utils/time';
import { DateTime } from 'luxon';
import {I18nService} from "@app/i18n";
import { TranslateService } from '@ngx-translate/core';
import { getCountryCode } from 'countries-list';

export interface ContactInfoForm{
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  comment: string;
  gdprConsent: boolean;
  marketingConsent: boolean;
}

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.css'],
})
export class ContactInfoComponent implements OnInit, OnChanges{
  @Input() numberOfGuests: number = 0;
  @Input() selectedDate?: DateTime;
  @Input() selectedTime?: number = 0;
  @Input() isLoading: boolean = false;
  @Input() country?: string;
  @Input() termsAndConditionsLink?: string;
  @Input() marketingLink?: string;

  selectedTimeHumanReadable?: string;

  countryISO: string = "";

  @Output() onBack = new EventEmitter<boolean>();
  @Output() onSubmitContactInfo = new EventEmitter<ContactInfoForm>();

  contactInfoForm = new FormGroup({
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    phone: new FormControl(),
    email: new FormControl('', [Validators.required, Validators.email]),
    comment: new FormControl(''),
    gdprConsent: new FormControl(false, [Validators.requiredTrue]),
    marketingConsent: new FormControl(false, [])
  });

  textLabelsPhoneInput = {
    mainLabel: 'Phone number',
    codePlaceholder: 'Country code',
    searchPlaceholderLabel: 'Search',
    noEntriesFoundLabel: 'No countries found',
    nationalNumberLabel: 'Phone number',
    hintLabel: 'Select country and type your phone number',
    invalidNumberError: 'Phone number is not valid',
    requiredError: 'This field is required',
  };

  constructor(
    private i18nService: I18nService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    if(this.selectedTime){
      this.selectedTimeHumanReadable = convertMinutesSinceMidnightToHumanReadableTime(this.selectedTime, this.i18nService.use12HourClock);
    }

    this.translateService
      .get([
        'general.search.phone-input.mainLabel',
        'general.search.phone-input.codePlaceholder',
        'general.search.phone-input.searchPlaceholderLabel',
        'general.search.phone-input.noEntriesFoundLabel',
        'general.search.phone-input.nationalNumberLabel',
        'general.search.phone-input.hintLabel',
        'general.search.phone-input.invalidNumberError',
        'general.search.phone-input.requiredError',
      ])
      .subscribe((translations) => {
        this.textLabelsPhoneInput = {
          mainLabel: translations['general.search.phone-input.mainLabel'],
          codePlaceholder:
            translations['general.search.phone-input.codePlaceholder'],
          searchPlaceholderLabel:
            translations['general.search.phone-input.searchPlaceholderLabel'],
          noEntriesFoundLabel:
            translations['general.search.phone-input.noEntriesFoundLabel'],
          nationalNumberLabel:
            translations['general.search.phone-input.nationalNumberLabel'],
          hintLabel: translations['general.search.phone-input.hintLabel'],
          invalidNumberError:
            translations['general.search.phone-input.invalidNumberError'],
          requiredError:
            translations['general.search.phone-input.requiredError'],
        };
      });

      if (!this.country) return;
      const countryCode = getCountryCode(this.country);
      console.log(countryCode)
      this.countryISO = countryCode ? countryCode.toLowerCase() : "";
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if(propName === 'isLoading'){
        const change = changes[propName];
        if(change.currentValue === true){
          this.contactInfoForm.disable();
        }else{
          this.contactInfoForm.enable();
        }
      }
    }
  }

  back(){
    this.onBack.emit(true);
  }

  submit(){
    this.contactInfoForm.markAllAsTouched();
    if(this.contactInfoForm.valid){
      const contactInfo = this.contactInfoForm.value as ContactInfoForm;
      contactInfo.phone = contactInfo.phone.split(' ').join(''); // Remove whitespaces to be valid.
      this.onSubmitContactInfo.emit(contactInfo);
    }
  }
}
