

  <ng-container *ngIf="isLoading">
    <app-shimmer [styling]="{ height: '54px', width: '100%', 'margin-top':'30px' }"></app-shimmer>
    <div class="radioButtons mt-10">
      <div *ngFor="let item of [].constructor(18); let i = index">
        <div class="radioButton">
          <app-shimmer [styling]="{ height: '54px', width: '54px', 'border-radius': '50%' }"></app-shimmer>
        </div>
      </div>
    </div>
  </ng-container>


  <div *ngIf="!timeslotsIsAvailable && !isLoading" class="text-center">
    <h1 class="text-xl" translate>select_time.nocapacityh1</h1>
    <p class="mt-5">{{'select_time.nocapacitydesc' | translate: {xDateW: (selectedDate | localizedDateString) } }}</p>

    <div class="flex flex-row justify-center gap-4 mt-12">
      <button [routerLink]="['/waiting-list']" [queryParams]="{ tenantId: tenantId, restaurantId: restaurantId, selectedDate: selectedDate }" type="button" class="btn btn-outline">
        <span translate>select_time.jointhewaitinglist</span>
      </button>
      <button (click)="back()" type="button" class="btn btn-outline">
        <span translate>select_time.tryanotherdate</span>
      </button>
    </div>

  </div>

  <div *ngIf="timeslotsIsAvailable">
    <h1 class="text-center text-xl md:text-2xl" translate>select_time.title</h1>
    <div class="flex flex-row justify-center">
      <div class=" font-thin text-sm" [translateParams]="{ guests: numberOfGuests }" translate>stepper.date_summary</div>
      <div style="width: 40px;"></div>
      <div class="font-thin text-sm">{{ selectedDate | localizedDateString }} </div>
    </div>
  </div>

  <form (ngSubmit)="submit()" [formGroup]="seletedTimeForm" *ngIf="timeslotsIsAvailable" class="mt-12">
    <div class="times">
      <div class="radioButtons">
        <ng-container *ngIf="!isLoading">

          <!-- Display grouped timeslots -->
          <div class="flex flex-col items-start gap-6" *ngIf="servingAreaChoice">

            <div class="flex flex-col gap-2 w-full items-center" *ngIf="groupedTimeslots.length > 0">
              <p translate> select_time.available_areas </p>
              <div class="flex gap-2">
                <button
                  class="btn btn-outline btn-sm area-select-btn"
                  type="button"
                  *ngFor="let group of groupedTimeslots"
                  [ngClass]="{'btn-active': group.area.id === selectedArea?.area?.id}"
                  (click)="onAreaChange(group.area.id)"
                >
                  {{ group.area.name | async }}
                </button>
              </div>
            </div>

            <div *ngIf="selectedArea?.timeslots?.length === 0" class="text-center">
              <p translate> select_time.no_times_available </p>
            </div>

          </div>

            <div class="flex flex-col gap-2 w-full items-center" *ngFor="let group of selectedArea?.timeslots">
              <h3 class="text-lg"> {{ group.label }} </h3>
              <div class="radioButtons">
                <div *ngFor="let timeSlot of group.slots">
                  <input
                    [id]="timeSlot.minutesfrommidnight"
                    formControlName="time"
                    [value]="timeSlot.minutesfrommidnight"
                    type="radio"
                    class="roundedPrimaryButton"
                  />
                  <label style="width: 70px; height: 70px;" [for]="timeSlot.minutesfrommidnight">{{ timeSlot.readableTime }}</label>
                </div>
              </div>
          </div>

          <!-- Display all timeslots -->
          <div class="radioButtons" *ngIf="!servingAreaChoice">
            <div *ngFor="let timeSlot of allTimeslots">
              <input
                [id]="timeSlot.minutesfrommidnight"
                formControlName="time"
                [value]="timeSlot.minutesfrommidnight"
                type="radio"
                class="roundedPrimaryButton"
              />
              <label style="width: 70px; height: 70px;" [for]="timeSlot.minutesfrommidnight">{{ timeSlot.readableTime }}</label>
            </div>
          </div>

        </ng-container>
      </div>
    </div>

    <div *ngIf="showWaitlistInformation && timeslotsIsAvailable && !isLoading" class="mt-12 text-center">
      <p class="mt-5">{{'select_time.cantfindsuitabletime' | translate }}</p>
      <div class="flex flex-row justify-center gap-4 mt-1">
        <button [routerLink]="['/waiting-list']" [queryParams]="{ tenantId: tenantId, restaurantId: restaurantId, selectedDate: selectedDate }" type="button" class="btn btn-outline">
          <span translate>select_time.jointhewaitinglist</span>
        </button>
      </div>
    </div>

    <div *ngIf="!isLoading && !timeslotsIsAvailable" class="no-times-available">
      <h1 class="mt-12 text-2xl" translate>select_time.no_times_available</h1>
      <p class="mt-3" translate>select_time.contact_restaurant</p>
      <div *ngIf="contactPhone || contactEmail" class="contact-info mt-6">
        <h4 translate>select_time.contact_info</h4>
        <p *ngIf="contactPhone"><span translate>select_time.phone</span>:&nbsp;<a [href]="'tel:' + contactPhone">{{contactPhone}}</a></p>
        <p *ngIf="contactEmail"><span translate>select_time.email</span>:&nbsp;<a [href]="'mailto:' + contactEmail">{{contactEmail}}</a></p>
      </div>
    </div>

    <div *ngIf="seletedTimeForm.controls['time'].invalid && seletedTimeForm.controls['time'].touched">
      <p translate class="error">select_time.no_time_selected</p>
    </div>

    <div class="flex justify-between mt-12">
      <button (click)="back()" type="button" class="btn btn-outline">
        <span translate>common.back</span>
      </button>
      <!-- ngClass blir brukt istedenfor TailwindCSS 'disabled: selector' da denne ikke fungerer som forventet. -->
      <button type="submit" class="btn" [ngClass]="{
        'btn-primary': timeslotsIsAvailable,
        'btn-disabled': !timeslotsIsAvailable}"
        [disabled]="!timeslotsIsAvailable">
        <span translate>common.next</span>
      </button>
    </div>

  </form>

