<div *ngIf="!isLoading && restaurantHasOpeningHours">

  <h1 class="text-center text-xl md:text-2xl" translate>select_date.title</h1>

  <div class="text-center font-thin text-sm" [translateParams]="{ guests: numberOfGuests }" translate>stepper.date_summary</div>

  <form (ngSubmit)="submit()" [formGroup]="selectedDateForm" class="mt-12">
    <div class="bg-[var(--background-color)] flex justify-center">
      <ngb-datepicker
        [firstDayOfWeek]="firstDayOfWeek"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [markDisabled]="getDisabledDates"
        [startDate]="startDate"
        class="datepicker" #dp formControlName="date"
      />
    </div>

    <div *ngIf="selectedDateForm.controls['date'].invalid && selectedDateForm.controls['date'].touched">
      <p translate class="error">select_date.no_date_selected</p>
    </div>

    <div class="flex flex-row justify-between mt-12">
      <button (click)="back()" type="button" class="btn btn-outline">
        <span translate>common.back</span>
      </button>
      <button type="submit" class="btn btn-primary">
        <span translate>common.next</span>
      </button>
    </div>
    
  </form>
</div>

<div *ngIf="!isLoading && !restaurantHasOpeningHours">
  <h1 class="text-center text-xl md:text-2xl mt-12" translate>select_date.noOpeningHours</h1>
  <div class="text-center font-thin text-sm" translate>select_date.noOpeningHoursDesc</div>
</div>
