import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SeatlyBookingWidgetComponent } from './pages/seatly-booking-widget/seatly-booking-widget.component';
import { MissingAttributesErrorComponent } from './pages/missing-attributes-error/missing-attributes-error.component';
import { BookingReviewComponent } from './pages/booking-review/booking-review.component';
import {CancellationConfirmedComponent} from "@app/pages/cancellation-confirmed/cancellation-confirmed.component";
import {WaitingListComponent} from "@app/pages/waiting-list/waiting-list.component";
import {TermsAndConditionsComponent} from "@app/pages/terms-and-conditions/terms-and-conditions.component";

const routes: Routes = [
  { path: '', redirectTo: 'booking', pathMatch: 'full'},
  { path: 'booking', component: SeatlyBookingWidgetComponent, title: 'Book table'},
  { path: 'm/:bookingUuid', component: BookingReviewComponent, title: 'My Booking'},
  { path: 'waiting-list', component: WaitingListComponent, title: 'Waiting list'},
  { path: 'cancellation-confirmed', component: CancellationConfirmedComponent, title: 'My Booking'},
  { path: 'terms', component: TermsAndConditionsComponent, title: 'Terms and conditions'},
  { path: 'attribute-error', component: MissingAttributesErrorComponent, title: 'Attribute error'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
