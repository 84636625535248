<h1>Terms and Conditions for Reservations</h1>
<p>These Terms and Conditions govern your use of the reservation services provided by our venue. By completing a reservation, you agree to these Terms and Conditions. If you do not agree, please do not proceed with your reservation.</p>

<h2>1. Reservation Services</h2>
<p><strong>1.1.</strong> We use a third-party software solution to facilitate table reservations. However, all reservations are directly managed by us, the restaurant, and not the software provider.</p>
<p><strong>1.2.</strong> Your reservation is subject to availability and may require confirmation. We reserve the right to cancel or modify your reservation due to unforeseen circumstances.</p>

<h2>2. Personal Data</h2>
<h3>2.1. Data Collection and Use</h3>
<p>To facilitate your reservation, we collect and process personal data, such as your name, contact information, and any special requests. We might also add internal information based on our conversations and your visits. This information is used solely for:</p>
<ul>
  <li>Managing your reservation.</li>
  <li>Contacting you about reservation updates or changes.</li>
  <li>Tailoring your experience with our service.</li>
  <li>Complying with legal obligations.</li>
</ul>
<h3>2.2. Data Sharing</h3>
<p>Your data may be shared with trusted third parties solely for purposes of processing reservations or as required by law. We do not sell your personal information to any third party.</p>
<h3>2.3. Global Data Transfers</h3>
<p>Depending on your location, your data may be processed in countries outside your home jurisdiction. We implement safeguards to protect your data, such as encryption and contractual agreements, in accordance with applicable laws.</p>

<h2>3. Your Rights</h2>
<p>Depending on your location, you may have the following rights regarding your personal data:</p>
<ul>
  <li><strong>Access:</strong> Request a copy of the data we hold about you.</li>
  <li><strong>Correction:</strong> Request corrections to your personal information.</li>
  <li><strong>Deletion:</strong> Request deletion of your data, subject to legal or operational requirements.</li>
  <li><strong>Objection:</strong> Object to the processing of your data for specific purposes, such as marketing.</li>
  <li><strong>Data Portability:</strong> Request that your data be transferred to another provider where technically feasible.</li>
</ul>
<p>For requests, please contact us. See our website for up-to-date contact information.</p>

<h2>4. Marketing Communications</h2>
<p><strong>4.1.</strong> You may have the option to consent to receive marketing communications from us. If you agree, we may contact you with offers, promotions, and updates.</p>
<p><strong>4.2.</strong> You may withdraw your consent at any time by following the unsubscribe instructions in our communications or contacting us directly.</p>

<h2>5. Cookies and Tracking Technologies</h2>
<p>We may use cookies and similar technologies to enhance your experience when making a reservation. By using our services, you agree to the use of cookies in accordance with applicable laws. You can manage cookie preferences through your browser settings.</p>

<h2>6. Reservation Modifications and Cancellations</h2>
<p><strong>6.1.</strong> You are responsible for providing accurate and complete information when making a reservation.</p>
<p><strong>6.2.</strong> Reservations may be subject to cancellation or rescheduling policies specific to our restaurant. Please contact us directly for further information.</p>

<h2>7. Limitations of Liability</h2>
<p><strong>7.1.</strong> We are not responsible for any third-party failures or interruptions, including issues related to the software provider facilitating reservations.</p>
<p><strong>7.2.</strong> Our liability for any damages related to your reservation is limited to the maximum extent permitted by law.</p>

<h2>8. Jurisdiction-Specific Information</h2>
<p>Depending on your location, additional rights or obligations may apply:</p>
<ul>
  <li><strong>European Union (GDPR):</strong> You have the right to lodge a complaint with your local Data Protection Authority.</li>
  <li><strong>California (CCPA):</strong> You may request details about the personal information we collect, disclose, and sell (if applicable).</li>
  <li><strong>Brazil (LGPD):</strong> You can contact us to exercise your rights under LGPD.</li>
</ul>
<p>Please contact us if you have any questions about your local rights.</p>

<h2>9. Changes to These Terms</h2>
<p>We reserve the right to update these Terms and Conditions at any time. Any changes will be posted on our website or provided during the reservation process. Continued use of the reservation services constitutes acceptance of the updated terms.</p>

<h2>10. Contact Information</h2>
<p>Our contact information is available on our website.</p>
